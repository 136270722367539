body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background:#170f24;
}

h1,h2,h3,h4,h5,h6{
  margin: 0;
}

a {
  text-decoration: none;
}

p {
  margin: 0;
  color:#fff;
}

h1,h2,h3,h4,h5,h6 {
  color:#fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.top_nav {
  background-image: linear-gradient(180deg, #2f1852 14%, #170f24 100%);
  position: sticky;
  top: 0;
  z-index: 9;
}

.nav-link {
  color:#a8a8a8;
  font-size: 18px;
  font-weight: 700;
  padding: 0;
}

.nav-link img {
  width:24px;
  height:24px;
  margin-right: 5px;
}

.nav-link:hover {
  color:#fff;
}

.navbar-nav .active {
  background-color: hsla(0,0%,100%,.07);
  padding: 10px 14px;
  border-radius: 26px;
}

.tab-content .active {
  background-color: hsl(222.35deg 18.68% 17.84%);
  border-radius: 26px;
}

.active .nav-link {
  color: #fff!important;;
}

.navbar-nav .nav-item {
  margin:0 8px;
}

.navbar-nav {
  align-items: center;
}



.menu_btn ul  {
  float: right;
  padding-top: 8px;
  margin: 0;
  justify-content: end;
}

.menu_btn ul li {
  margin:0 15px;
  float: left;
}

.btn-secondary {
  background-color: inherit;
  border: 2px solid hsla(0,0%,100%,.1);
  padding: 7px 10px;
  border-radius: 10px;
  font-weight: 700;
}

.btn-primary {
  background-color: inherit;
  border: 2px solid #940696;
  padding: 7px 10px;
  border-radius: 10px;
  font-weight: 700;
  color:#fff;
  font-size: 20px;
}

.btn-primary:hover {
background-color: #930696ab;
border:2px solid #930696ab;
}

.btn-success img {
  width:24px;
  height:24px;
}

.btn-primary img {
  width:24px;
  height:24px;
  margin-left: 5px;
}

.btn-secondary img {
  width:18px;
  height: 18px;
  margin-right: 5px;
}


.main-heading {
  color: #fff;
  text-align: center;
  font-size: 50px;
}


.main_box {
  padding: 30px 0;
}

.card {
  margin-top: 40px;
  background: #170f24;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px -1px 11px 4px #93069640;
  border:1px solid #930696;
}

.card-title {
  color: #fff;
  margin: 0;
  display: flex;
  align-items: center;
}


.swap_box_icon {
  display: flex;
  align-items: center;
}

.swap_box_icon i {
  background: #212630;
    border-radius: 6px;
    padding: 6px;
    margin-right: 10px;
    color:#fff;
    font-size: 30px;
}



.swap_box {
  background: #170f24;
  border-radius: 10px;
  padding: 20px 16px;
  color: #a8a8a8;
  margin-top: 20px;
  box-shadow: 0px -1px 11px 4px #93069640;
  border: 1px solid #930696;
}

.card_heading img {
  height: 20px;
  width: 20px;
  margin-right: 5px;
}
.swap_box_icon h5 {
  margin:0;
}

.pointer {
  cursor: pointer;
  background: #212630;
  border-radius: 6px;
  padding: 6px;
  margin-right: 10px;
  width:40px!important;
  height:40px!important;
}




/* Modal */

.modal-content {
  border: 1px solid #930696;
  background: #170f24;
  box-shadow: 0px -1px 11px 4px #93069640;
}

.swap_modal ul li div {
  cursor: pointer;
  background: #ffffff17;
  padding: 10px 0;
  width: 80px;
  text-align: center;
  margin-right: 10px;
  border-radius: 10px;
  color: #fff;
  font-weight: 700;
}

.swap_modal ul li:nth-child(3) div {
  background-color: #940696;
  color:#fff;
}

.swap_modal ul li {
  color:#fff;
}


.swap_modal ul li input {
  width: 40%;
  background: #ffffff17;
  border: 0;
  height: 45px;
  color: #fff;
}
.swap_modal ul li input:focus {
  background-color: #15181d;
  color:#fff;
}

.qu {
  width:20px;
}

/* end modal */

.enter_amount img {
  height: 40px;
  width:40px;
}
.enter_amount p {
  color:#a8a8a8;
  margin:0;
}

.history_box {
  text-align: center;
  
}

.history_box h6 {
  color:#a8a8a8;
  margin-top: 20px;
}

.history_box img {
  width:5%;
}

.bnb_chain_i {
  background: #ffffff17;
  position: relative;
  border-radius: 10px;
  padding: 10px;
  margin-top: 20px;
}

.bnb_chain_i input {
  background:inherit;
  color:#fff;
}

.bnb_chain_i img {
  height:20px;
  width:20px;
}

.swap_arrow_icon img {
  width:20px;
  margin:20px auto;
}

.bg-btn {
  background-color: #940696;
}



.dropdown-menu {
  /* position: absolute; */
  top: 50px; 
  background: #212630;
  box-shadow: 0 10px 10px rgb(0 0 0 / 10%);
  border-radius: 10px;
  padding:  10px 10px;
  
}
.dropdown-menu2 {
  top:30px;
}
.dropdown-item {
  color:#fff;
  font-weight: 700;
}

.dropdown-item:hover {
  background-color: inherit;
  color:#fff;
}

.dropdown:hover .dropdown-menu {
display: block;
}


/* dropdown */

select {
  display: none !important;
}

.dropdown-select {
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0) 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#40FFFFFF', endColorstr='#00FFFFFF', GradientType=0);
  background-color: #fff;
  border-radius: 6px;
  border: solid 1px #eee;
  box-shadow: 0px 2px 5px 0px rgba(155, 155, 155, 0.5);
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  float: left;
  font-size: 14px;
  font-weight: normal;
  height: 42px;
  line-height: 40px;
  outline: none;
  padding-left: 18px;
  padding-right: 30px;
  position: relative;
  text-align: left !important;
  transition: all 0.2s ease-in-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
  width: auto;

}

.dropdown-select:focus {
  background-color: #fff;
}

.dropdown-select:hover {
  background-color: #fff;
}

.dropdown-select:active,
.dropdown-select.open {
  background-color: #fff !important;
  border-color: #bbb;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05) inset;
}

.dropdown-select:after {
  height: 0;
  width: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #777;
  -webkit-transform: origin(50% 20%);
  transform: origin(50% 20%);
  transition: all 0.125s ease-in-out;
  content: '';
  display: block;
  margin-top: -2px;
  pointer-events: none;
  position: absolute;
  right: 10px;
  top: 50%;
}

.dropdown-select.open:after {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.dropdown-select.open .list {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  pointer-events: auto;
}

.dropdown-select.open .option {
  cursor: pointer;
}

.dropdown-select.wide {
  width: 100%;
}

.dropdown-select.wide .list {
  left: 0 !important;
  right: 0 !important;
}

.dropdown-select .list {
  box-sizing: border-box;
  transition: all 0.15s cubic-bezier(0.25, 0, 0.25, 1.75), opacity 0.1s linear;
  -webkit-transform: scale(0.75);
  transform: scale(0.75);
  -webkit-transform-origin: 50% 0;
  transform-origin: 50% 0;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.09);
  background-color: #fff;
  border-radius: 6px;
  margin-top: 4px;
  padding: 3px 0;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 999;
  max-height: 250px;
  overflow: auto;
  border: 1px solid #ddd;
}

.dropdown-select .list:hover .option:not(:hover) {
  background-color: transparent !important;
}
.dropdown-select .dd-search{
overflow:hidden;
display:flex;
align-items:center;
justify-content:center;
margin:0.5rem;
}

.dropdown-select .dd-searchbox{
width:90%;
padding:0.5rem;
border:1px solid #999;
border-color:#999;
border-radius:4px;
outline:none;
}
.dropdown-select .dd-searchbox:focus{
border-color:#12CBC4;
}

.dropdown-select .list ul {
  padding: 0;
}

.dropdown-select .option {
  cursor: default;
  font-weight: 400;
  line-height: 40px;
  outline: none;
  padding-left: 18px;
  padding-right: 29px;
  text-align: left;
  transition: all 0.2s;
  list-style: none;
}

.dropdown-select .option:hover,
.dropdown-select .option:focus {
  background-color: #f6f6f6 !important;
}

.dropdown-select .option.selected {
  font-weight: 600;
  color: #12cbc4;
}

.dropdown-select .option.selected:focus {
  background: #f6f6f6;
}

.dropdown-select a {
  color: #aaa;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}

.dropdown-select a:hover {
  color: #666;
}


.oplist img {
  height: 25px;
  width: 25px;
  margin-right: 10px;
}

.oplist {
  display: flex;
  align-items: center;
}
.oplist h6 {
  color:#fff;
}

.table_heading {border-bottom: 1px solid hsla(0,0%,59.2%,.2);
  padding-bottom: 25px;
}

.item {
  position: relative;
  padding: 20px 30px;
  width: 100%;
  border-radius: 8px;
  margin-right: 20px;
  overflow: hidden;
  margin-top: 25px;
}
.flex-all {
  display: flex;
  justify-content: space-between;
}
.name {
  font-weight: 700;
  font-size: 24px;
  color: #fff;
}

.flex-align {
  display: flex;
  align-items: center;
}

.chain img {
  width: 20px;
}

 .chain span {
  font-size: 16px;
  margin-left: 5px;
  color: #fff;
  opacity: .4;
}

.lineb {
  display: flex;
  flex-direction: row-reverse;
  font-weight: 700;
  font-size: 20px;
  color: #fff;
  margin-top: 25px;
}

 .item .coin{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100px;
}

.item1 {
  background: #ffffff17;
    border-radius: 9px;
    margin-right: 20px;
    overflow: hidden;
    padding: 20px 30px;
    width: 100%;
    margin-top: 25px;
}

.line-a {
  font-weight: 700;
  font-size: 16px;
  color: #fff;
}

.item1 .line-b {
  margin-top: 14px;
}

.item1 .line-b .left {
  font-weight: 700;
  font-size: 20px;
  color: #fff;
}

 .item1 .line-b .right {
  font-weight: 500;
  font-size: 16px;
  color: #fff;
  opacity: .4;
}


.content {
  padding: 40px 0;
}
/*
.filter-wrapper{
  padding: 30px 0;
}*/

.filter-checkbox{
  margin-left: 30px
}
.filter-checkbox:first-child{
  margin-left:0;
}

.nav-tabs .nav-link.active {
  background:inherit;
  border:0;
  border-bottom: 3px solid #fff;
  border-radius: 0;
  padding: 0 0 10px 0;
  color:#fff;
}


.nav-tabs li {
  margin-right:20px;
}

.nav-tabs .nav-link {
  border:0;
}

.footer_bg {
  background-color: #15181d;
  padding: 60px 0 50px;
  margin-top: 80px;
}

.footer_title {
  font-weight: 500;
  font-size: 20px;
  color: #ddd;
  margin-bottom: 25px;
}

.footer_logo img {
  width:120px;
}

.footer_list ul {
padding: 0;
}

.footer_list ul li {
  list-style: none;
  color: #898a8c;
  margin:10px 0;
}

.social_list ul {
  display: flex;
  list-style: none;
  padding: 0;
  
}
.social_list ul li {
  margin: 0 15px;
  font-size: 24px;
  color: #a1a2a4;
}

.search-box {
  position: relative;
}
.search-box .form-control {
  background: #ffffff17;
  border-radius: 10px;
  height: 40px;
  width: 100%;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  padding-left: 54px;
  border: 1px solid transparent;
}

.form-control:focus {
  background-color: inherit;
  color:#fff;

}

.search-box i {
  opacity: .6;
  position: absolute;
  top: 8px;
  left: 15px;
  width: 24px;
  color: #fff;
  font-size: 20px;
}



.new_drop {
  height: 44px;
  border: 2px solid hsla(0,0%,100%,.1);
  border-radius: 10px;
  padding: 0 12px;
  margin-right: 10px;
  background: inherit;
  color: #fff;
  box-shadow: 0px!important;
}

/* #containerexample {display: none}
#loadercontainer{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
} */

.dashboard_volume {
  background: #ffffff17;
  border-radius: 10px;
  padding: 20px 0;
  position: relative;
  overflow: hidden;
}

.total-item {
  flex: 1;
  padding: 10px 0px;
  text-align: center;
  border-right: 1px solid hsla(0,0%,84.7%,.2);
}

.total-item .text {
  font-size: 16px;
  color: #fff;
  font-weight: 500;
}
.total-item .num {
  font-size: 30px;
  color: #fff;
  font-weight: 700;
  margin-top: 10px;
}



/* Data Table */


.odd td {
  color:#fff;
}

.even td {
  color:#fff;
  text-align: center;
  font-weight: 700;
}

.sorting {
  color:#fff;
}

#example_filter {
  display: none;
}

.dataTables_length {
  display: none;
}

.filters {
  display: none;
}

.dataTables_info {
  display: none;
}

.dataTables_paginate {
  display: none;
}

.eth_icon {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.eth_icon img {
  width: 35px;
  height: 35px;
}

.eth_text h4 {
  margin: 0;
  font-size: 16px;;
  color:#fff;
}

.eth_text a {
  border: 2px solid #930696;
  padding: 5px 10px;
  margin: 0;
  border-radius: 25px;
  color: #fff;
  font-size: 12px;
  box-shadow: 0px -1px 11px 4px #93069640;
}

.eth_text span {
  font-size: 12px;
  font-weight: 700;
  color: #a6a8ad;
}

.odd td {
  font-size: 16px;
  font-weight: 700;
  text-align: center;
}

.green {
  color:#31d9ac;
}

.sorting {
  text-align: center!important;
}

.table tr th {
  border-bottom: 1px solid hsla(0,0%,59.2%,.2)!important;
  text-align: center;
  color: #fff;
  padding: 20px 0!important;
}

.table tr {
  border-bottom: 1px solid hsla(0,0%,59.2%,.2)!important;
}

.table tr td {
  padding: 20px 0;
  font-weight: 700;
  text-align: center;
  border-bottom: 0;
  /* background:#2c2538; */
}

.caret i {
  background: #15181d;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    line-height: 30px;
    text-align: center;
    color:#fff;
    cursor: pointer;
}

.t_list ul {
  padding: 0;
list-style: none;
}
.t_list ul li p {
  color: #252a36;
}

.t_list ul li {
  color: #a8a8a8;
  display: flex;
  align-items: center;
  margin: 5px 0;
}

.t_list ul li i {
  margin-left: 12px;
  color: #31d9ac;
}

.start_farming {
  border: 1px solid #eeeaf4;
  padding: 10px;
  border-radius: 10px;
}
.start_farming p {
  color:#252a36;
  text-align: left;
  margin: 10px 0;
}

.display tr:nth-child(even) td {
  padding: 0px!important;
}

.display tr:last-child {
  border-bottom: 0px!important;
}

/* .accordion{
  margin: 10px 0;
} */

/* End Data Table */


.staked_heading {
  background-image:linear-gradient(28deg,#10baff,#930696 38%,#ad52ff);
  padding: 20px 25px;
  border-radius: 10px;
}

.staked_heading h3 {
  color:#fff;
  margin:0;
  font-size: 24px;
}

.staked_heading p {
  color:#fff;
  margin:0;
  font-size: 14px;
  font-weight: 500;
}

.stake_list img {
  height: 20px;
  width: 20px;
}

.stake_list h6 {
  margin-left: 5px;
}

.stake_list p {
  margin-left: 5px;
  color: #4c595c;
  font-weight: 500;
} 



.stake_list ul {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 10px 0;
}

.stake_list ul li {
  margin: 5px 8px;
}


.stakeul {
  border-bottom: 1px solid #363a45!important;
}

.bnb_chain_s {
  background: #ffffff17;
  position: relative;
  border-radius: 10px;
  padding: 10px;
  
}

.bnb_chain_s input {
  background: inherit;
}

.bnb_chain_s img {
  height: 20px;
  width: 20px;
}

.stake_-- {
  color:#a8a8a8;
}

.stake_active {
  background-color: inherit!important;
}

.stake_text p {
  font-weight: 700;
}

.stake_text i {
  background-color: #930696;
  margin-left: 10px;
  border-radius: 50px;
  height: 20px;
  width: 20px;
  text-align: center;
  line-height: 20px;
}

.staked_info img {
  width:50px;
}

.stake_imgtext {
  margin-left: 10px;
}

.faq {
  border-top: 1px solid #363a45;
  border-bottom: 1px solid #363a45;
  padding: 30px 0;
  margin-top: 40px;
}

.faq p{
  text-align: justify;
}

.metamask h3  {
  color:#fff;
  font-size: 22px;
}
.metamask img {
  width: 60px;
}

.para {
  background: #ffffff17;
  color: #fff;
  padding: 15px;
  text-align: center;
  border-radius: 10px;
  margin: 30px 0;
}

.liquidiy {
  color:#fff;
}

.liquidiy p {
  margin:10px 0;
}

.liquidiy i {
  font-size: 24px;
  margin-right: 10px;
  color:#fff;
}

.connect_wallet .card-body {
  background-color: #15181d;
  margin: 20px 0;
  border-radius: 10px;
}



.bOHOqq {
  color: #fff;
  font-weight: 600;
  line-height: 1.5;
  text-transform: uppercase;
  margin-bottom: 24px;
  font-size: 18px;
}

.default_box {
  color:#fff;
  margin:20px 0;
}

.confirm_box {
  margin: 10px 0;
}
.confirm_box img {
  height: 25px;
  width: 25px;
  margin-right: 10px;
}

.s_box {
  color:#fff;
  margin:5px 0;
}

.s_box i {
  margin-left: 10px;
  cursor: pointer;
}


.default_box i {
  margin-left: 10px;
  cursor: pointer;
}


.manage_box img {
  height: 35px;
  width: 35px;
  margin-right: 10px;
}
.manage_box span {
  color:#fff;
  font-weight: 500;
}


.transaction .nav-link.active {
  background: #930696;
  border: 0!important;
  border-bottom: 3px solid #930696;
  border-radius: 0;
  padding: 4px 15px;
  color: #fff;
  border-radius: 25px;
  font-size: 15px;
}

.transaction .nav-link {
  background: #0000005c;
  padding: 4px 15px;
  border-radius: 25px;
  color: #fff;
  font-size: 16px;
  border:0;
}


.liqui_input {
  width: 52px;
  margin-top: 4px;
}
.iSRZGa {
  background-color: #0000005c;
  border-radius: 16px;
  box-shadow: inset 0px 2px 2px -1px rgba(74,74,104,.1);
  color: #fff;
  display: block;
  font-size: 16px;
  height: 32px;
  outline: 0px;
  padding: 0px 16px;
  width: 100%;
  border: 1px solid #666;
}



.table_liqui tr {
  border-bottom: 0!important;
}

.table_liqui tr td {
  padding: 0px!important;
}

.switchSmall2 {
  display: inline-block;
}
.switchSmall2 input {
  display: none;
}
.switchSmall2 small {
 display: inline-block;
 width: 52px;
 height: 32px;
 background: #455a64;
 border-radius: 30px;
 position: relative;
 cursor: pointer;
}
.switchSmall2 small:before {
 content: "";
 position: absolute;
 width: 30px;
 height: 30px;
 background: #fff;
 border-radius: 50%;
 top: 1px;
 left: -1px;
 transition: .3s;
 box-shadow: -3px 0 3px rgba(0,0,0,0.25);
}
.switchSmall2 input:checked ~ small {
  background: #930696;
  transition: .3s;
}
.switchSmall2 input:checked ~ small:before {
 transform: translate(20px, 0px);
 transition: .3s;
 box-shadow: 3px 0 3px rgba(0,0,0,0.25);
}


.wallet_box {
  background-color: #fff;
  padding: 10px 30px; 
}

.wallet_box h5 {
  font-weight: 700;
  margin: 10px 0px 10px;
}

.wallet_box p {
  color: #7a6eaa;
  font-size: 15px;
  margin-bottom: 20px;
  font-weight: 600;
  text-align: center;
}


 .wallet_list {
  text-align: center;
  margin: 10px 0;
 }

 .wallet_list p {
  color: #280d5f;
  font-weight: 500;
  line-height: 2.5;
  font-size: 12px;
 }

 .wallet_list img {
  height: 50px;
  width: 50px;
 }

 .read_more i {
  background: #f6f6f6;
  height: 50px;
  width: 50px;
  text-align: center;
  margin: 0 auto;
  border-radius: 10px;
  line-height: 50px;
  margin-top: 10px;
  cursor: pointer;
}

.read_more p {
  color: #280d5f;
  font-weight: 500;
  line-height: 2.5;
  font-size: 12px;
}


.liqui_box {
  position: relative;
}

.liqui2 tr {
  border: 1px solid #dedbdb!important;
  padding: 10px;
  border-radius: 10px;
  margin-top: 15px;
}

.liqui_img img {
  width:60%;
}

.liqui_img h2 {
  color:#fff;
  margin:20px 0;
  font-size: 20px;
}

.liqui_img {
  text-align: center;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,6%);
}




.c_w {
  width: 120px;
  white-space: nowrap;
  overflow: hidden;
  padding: 3px 15px;
  text-overflow: ellipsis;
}



.apr ul li {
  margin-right:10px;
}


.deposit-img img {
  width:25px;
  height: 25px;
}

.c_t_p a {
  color:#fff;
}
.c_t_p a img {
  height: 25px;
  width: 25px;
  margin-right: 10px;
}

.c_t_p {background: #252a36;
  padding: 10px 15px;
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.c_t_p i {
  color: #fff;
 
}

.comon_b li div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.comon_b li div img {
  height: 20px;
  width: 20px;
  margin-right: 5px;
}

.comon_b li:nth-child(3) div {
background: #15181d!important;
}



.v_list li img {
  height: 25px;
  width: 25px;
  margin-right: 10px;
  
}

.v_list ul {
  padding: 0;
  margin-top: 30px;
  height: 410px;
  overflow-x: hidden;
}

.v_list li {
  display: flex;
  align-items: center;
  margin: 10px 0;
  padding: 10px 10px;
  cursor: pointer;
  
}

.v_list li:hover {
  background-color: #15181d;
  border-radius: 10px;
}

/* width */
.v_list ul::-webkit-scrollbar {
  width: 5px;
}

 
/* Handle */
.v_list ul::-webkit-scrollbar-thumb {
  background: #888; 
}

.modal-open {
  overflow: scroll!important;
}


.deposit_a .form-control {
  padding-bottom: 30px;
}

.deposit_a {
  position: relative;
}

.deposit_a p {
  position: absolute;
  color: #6c757d;
  top: 30px;
  left: 13px;
}


.navbar-toggler-icon {
  background-image: url('/public/img/new-bar.png')!important;
}

.men_resp {
  display: none;
}

@media only screen and (max-width: 767px) {
  .menu_btn ul li {
    display: none;
  }

  .men_resp ul li:nth-child(1) {
    display: block!important;
  }
  .men_resp {
    display: block!important;
  }
}

.elementor-background-overlay {
  background-attachment: fixed;
}

.elementor-background-overlay {
  background-image: url(http://tykit.rometheme.pro/linx/wp-content/uploads/sites/6/2021/06/bg.png);
  background-position: center center;
  background-repeat: no-repeat;
  opacity: 0.65;
  /* transition: background 0.3s, border-radius 0.3s, opacity 0.3s; */
}

.elementor-background-overlay{
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  z-index: -1;
}

.btn-close {
  background-image: url('/public/img/times.png')!important;
}

.pricing ul {
  display: flex;
  list-style: none;
  padding: 0;
  color:#fff;
  align-items: center;
  justify-content: space-between;
  margin: 30px 0 0;
}

.pricing ul li img {
  height: 25px;
  width: 25px;
}

.pricing ul li:nth-child(1) {
  color:#0fb68a;
}

.transform_position {
  position: relative;
}

.center_transform img {
  margin-bottom: 20px;
}

.center_transform {
  position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,50%);
    width: 100%;
    text-align: center;
}

.token_model .form-control {
  background-color: inherit;
  border: 1px solid #dedbdb;
  color: #dedbdb;
  height: 50px;
  border-radius: 15px;
}
.token_model .form-control::placeholder {
  color:#dedbdb;
}

.modal-body .nav-tabs .nav-item {
    margin: 0;
    width: 50%;
}

.modal-body .nav-tabs .nav-item .nav-link.active {
  background-color: #7a6eaa;
  width: 100%;
  border-radius: 25px;
  border: 0;
  padding: 5px 0;
  margin: 0;
}

.modal-body .nav-tabs {    background: #212630;
  border-radius: 25px;
  border: 0;
  padding: 0;
  text-align: center;
  width: 100%;
}
.modal-body .nav-tabs .nav-link {
  margin: 0px auto;
}
.card-title i {

  font-size: 20px;
  margin-left: 10px;
}